/** @jsx jsx */
import React from 'react'
import { graphql } from 'gatsby'
import { Box, jsx } from 'theme-ui'
import HeroSection from '~/components/Content/Section/Hero'
import ContentCardSection from '~/components/Content/Section/ContentCard'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import './collective.css'

const constants = {
  backgroundColor: '#ABC5E9',
  navTextColor: '#512773',
  logoColor: '#512773',
}

const mediaType = media => {
  return media.__typename
}

const Collective = ({ data }) => {
  const { page } = data

  return (
    <Layout
      backgroundColor={constants.backgroundColor}
      navTextColor={constants.navTextColor}
      logoColor={constants.logoColor}
    >
      <Metadata title={page.name} />
      {/* <ContentPage page={page} /> */}

      <Box
        sx={{
          margin: '0 auto',
          maxWidth: 'calc(1200px + 3rem)',
          padding: '0 1.5rem',
          position: 'relative',
        }}>
          {page.sections.map(section => {
            return (section.media.map((media, index) => {
              return (
                <div>
                  {mediaType(media) === 'ContentfulHero' && (
                    <HeroSection hero={media} textColor={'rgb(81, 39, 115)'} />
                  )}
                </div>
              )
            }))
          })}
      </Box>

      <Box
        sx={{
          background: 'white',
        }}
      >
        <Box
            sx={{
              margin: '0 auto',
              maxWidth: 'calc(1200px + 3rem)',
              padding: '0 1.5rem',
            }}
          >
            {page.sections.map(section => {
              return (section.media.map((media) => {
                return (
                  <div>
                    {mediaType(media) === 'ContentfulContentCard' && (
                      <ContentCardSection contentCards={media} />
                    )}
                  </div>
                )
              }))
            })}
        </Box>
      </Box>

    </Layout>
  )
}

export default Collective

export const query = graphql`
  query CollectivePage($locale: String) {
    page: contentfulPage(
      slug: { eq: "collective" }
      node_locale: { eq: $locale }
    ) {
      ...ContentPageFragment
    }
  }
`
