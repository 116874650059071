/** @jsx jsx */
import Img from 'gatsby-image'
import { Box, Flex, Heading, jsx } from 'theme-ui'

const HeroSection = ({ hero, textColor }) => {
  const { id, heading, subheading, image } = hero
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        padding: ['2rem 0', '4rem 0']
      }}
    ><Flex
        key={id}
        sx={{
          maxWidth: '1200px',
          marginBottom: ['16px', null, '16px'],
          flexDirection: ['column', 'row-reverse'],
        }}
      >
        <Flex sx={{ width: ['100%', '60%'], display: ['none', 'flex'] }}>
          <Img
            fluid={image.fluid}
            title={image.title}
            sx={{ width: '100%' }}
          />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', '40%'],
            padding: ['0', '0 2rem 0 0', '0 2rem'],
            justifyContent: 'start'
          }}
        >
          <Heading
            sx={{
              color: textColor,
              fontSize: ['50px', '50px', '62px'],
              lineHeight: ['54px', '54px', '65px'],
              margin: '-2rem 0',
            }}
            as="h1" variant="h1"
            dangerouslySetInnerHTML={{
              __html: heading.childMarkdownRemark.html
            }}
            >
          </Heading>
          <Flex sx={{ width: '100%', display: ['flex', 'none'] }}>
            <Img
              fluid={image.fluid}
              title={image.title}
              sx={{ width: '100%' }}
            />
          </Flex>
          <Box
            sx={{
              color: textColor,
            }}
            dangerouslySetInnerHTML={{
              __html: subheading.childMarkdownRemark.html
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HeroSection
