/** @jsx jsx */
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Box, Flex, Heading, Text, jsx } from 'theme-ui'

const ContentCardSection = ({ contentCards, index }) => {
  const { id, title, description, label, media } = contentCards

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        padding: ['2rem 0', '4rem 0']
      }}
    >
      {!media && (
        <div>
          <Heading
            sx={{
              fontSize: ['34px', '50px', '62px'],
              lineHeight: ['40px', '54px', '65px'],
              textAlign: 'center',
            }}
            as="h1" variant="h1">
            {title}
          </Heading>
          { description && (
          <Box
            sx={{
              color: 'rgb(81, 39, 115)',
              textAlign: 'center',
            }}
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html
            }}
          />
          )}
        </div>
      )}

      {media && (
        <Flex
          key={id}
          sx={{
            maxWidth: '1200px',
            marginBottom: ['16px', null, '16px'],
            flexDirection: ['column', null, ( index % 2 ? 'row' : 'row-reverse' )],
          }}
        >
          <Flex sx={{ width: ['100%', null, '60%'] }}>
            <Img
              fluid={media.fluid}
              title={media.title}
              sx={{ width: '100%' }}
            />
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              width: ['100%', null, '40%'],
              padding: ['0', '0 2rem'],
              justifyContent: 'start'
            }}
          >
            <Text variant="link" sx={{ marginBottom: '0.5rem' }}>
              {label}
            </Text>
            <Heading
              sx={{
                fontSize: ['34px', '50px', '62px'],
                lineHeight: ['40px', '54px', '65px']
              }}
              as="h1" variant="h1">
              {title}
            </Heading>
            <Box
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

ContentCardSection.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.shape({}))
}

export default ContentCardSection
